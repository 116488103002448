import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/Button"
import ImageCarousel from "../components/img/ImageCarousel"
import LogoStream from "../components/img/LogoStream"

const TeacherPage = () => {
  const images = [
    {
      src: "/banners/teacher-banner.png",
      alt: "Treat Your Teacher",
      link: null,
    },
  ]

  return (
    <Layout>
      <Seo
        title={`The Dining Out Gift Card | Treat your teacher`}
        description={`Treat your children’s teacher to a Dining Out Gift Card – the perfect end-of-term present, valid in over 1700 restaurants and pubs.`}
      />

      <ImageCarousel images={images} />

      <LogoStream />

      <div className="content-wrapper">
        <div className="main">
          <h1>TREAT YOUR TEACHERS TODAY</h1>

          <p>
            They’ve taught your children all year and as summer approaches, it’s
            time to plan a special end-of-term present for our teachers.
          </p>

          <p>
            This year, why not set aside the candle, box of chocolates, handmade
            gift, or bottle of wine, and treat them to something they’ll really
            love. Treat the teacher to a Dining Out Gift Card that’s valid at
            over 1700 restaurants and bars.
          </p>
          
          <p>
            Before the parents' WhatsApp group starts buzzing with gift
            suggestions, surprise the teacher with our Dining Out Gift Card,
            featuring a wide range of restaurants, easy online purchase, and
            flexible loading options.
          </p>

          <p>
            Available from £5 to £250, our gift cards can be used at any of our
            locations. From stylish city favourites like Browns, Miller & Carter
            and All Bar One, to unique and character-filled pubs or high-street
            restaurants like Harvester and Toby Carvery.
          </p>

          <Button
            href="https://diningout.cashstar.com/"
            rel="noreferrer"
            target="_blank"
          >
            Buy a gift card
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default TeacherPage
